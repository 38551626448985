import React from "react"
import SEO from "../components/seo"
import Layout from "../components/layout"

export default function about() {
  return (
    <React.Fragment>
      <SEO title="about" />
      <Layout>
        <h2>About Me</h2>
        <h3>Professional Experience</h3>
        <h4>Crunchyroll (November 2023 - Current)</h4>
        <h5>Software Engineer III</h5>
        <ul>
          <li>
            Architect and feature lead for Crunchyroll's new metadata ingestion
            pipeline, leading ~5 engineers and collaborating with 4 other teams
            on development. This pipeline will ingest and process metadata for
            all new anime content made available to 15M+ Crunchyroll customers
          </li>
          <li>
            Designed live-streaming functionality in Crunchyroll's metadata
            pipeline and led ~5 engineers on its implementation, enabling
            simulcast access for 60k+ concurrent viewers per episode
          </li>
          <li>
            Introduced feature flags to the Content Delivery team, improving
            rollback speed up to 160x (~40min to 15sec)
          </li>
          <li>
            Owns and moderates the Content Delivery team's design and
            architecture reviews
          </li>
          <li>
            Mentors junior and mid-level engineers on topics such as code and
            design review, as well as distributed systems
          </li>
          <li>Technologies used: Go, TypeScript/Node.js, SQL, AWS, Docker</li>
        </ul>
        <h4>Amazon (May 2021 - May 2023)</h4>
        <h5>Software Development Engineer</h5>
        <ul>
          <li>
            Designed and implemented the horizontal carousel pagination feature
            on the Android application, enabling ~11.4M daily instances of
            faster content selection for customers
          </li>
          <li>
            Wrote backend code to aggregate, localize, and format data for the
            Android application, granting up to 1.8M daily customers access to
            Thursday Night Football recordings
          </li>
          <li>
            Mentored an intern, nurturing their growth to the point of them
            receiving a full-time return offer
          </li>
          <li>
            Technologies used: JavaScript/Node.js, AWS, SQL, Android (Kotlin,
            Java)
          </li>
        </ul>
        <h4>College of Southern Idaho (Dec 2016 - April 2021)</h4>
        <h5>Software Developer</h5>
        <ul>
          <li>
            Built a new storefront payment app, saving the college an estimated
            $8.19M in the event of a potential data breach
          </li>
          <li>
            Trained 20+ employees in IT on Git and GitHub and led the onboarding
            of 60+ projects to modern version control
          </li>
          <li>
            Built a 250k+ per-hour throughput data pipeline to automate the
            creation of all user accounts, sections, courses, terms, and
            enrollments in the college's learning management system
          </li>
          <li>
            Technologies used: JavaScript/Node.js, C#/.NET, SQL, Microsoft
            Azure, AWS
          </li>
        </ul>
        <h3>Education</h3>
        <h4>Arizona State University</h4>
        <h5>Bachelor of Science, Software Engineering</h5>
      </Layout>
    </React.Fragment>
  )
}
